declare var window: any;

const C1_CHAINID = "0x7d1";
// const A1_CHAINID = "0x30E0A"; // devnet one - not removed jic we need it
const A1_CHAINID = "0x7d2"; // A1 mainnet

export const addC1Milkomeda = async () => {
  if (!window.ethereum) {
    alert("You need to install MetaMask");
  }

  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: C1_CHAINID }],
    });
  } catch (error: any) {
    if (error.code === 4902) {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: C1_CHAINID,
              rpcUrls: ["https://rpc-mainnet-cardano-evm.c1.milkomeda.com/"],
              blockExplorerUrls: ["https://explorer-mainnet-cardano-evm.c1.milkomeda.com/"],
              chainName: "Milkomeda Cardano (C1)",
              nativeCurrency: {
                name: "MilkADA",
                symbol: "mADA",
                decimals: 18,
              },
            },
          ],
        });
      } catch (addError) {
        console.error(addError);
      }
    }
  }
};

export const addA1Milkomeda = async () => {
  if (!window.ethereum) {
    alert("You need to install MetaMask");
  }

  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: A1_CHAINID }],
    });
  } catch (error: any) {
    if (error.code === 4902) {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: A1_CHAINID,
              rpcUrls: ["https://rpc-mainnet-algorand-rollup.a1.milkomeda.com/"],
              blockExplorerUrls: ["https://explorer-mainnet-algorand-rollup.a1.milkomeda.com"],
              chainName: "Milkomeda A1",
              nativeCurrency: {
                name: "milkALGO",
                symbol: "mALGO",
                decimals: 18,
              },
            },
          ],
        });
      } catch (addError) {
        console.error(addError);
      }
    }
  }
};
