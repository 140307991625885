import { Locales } from "../../locales/languages";

export const externalUrlsByLocale = {
  milkomedaDoc: {
    [Locales.english]: "https://docs.milkomeda.com/",
    [Locales.japanese]: "https://docs.milkomeda.com/ja/",
  },
  milkomedaDocDeveloperCardano: {
    [Locales.english]: "https://docs.milkomeda.com/cardano/for-developers/",
    [Locales.japanese]: "https://docs.milkomeda.com/ja/cardano/for-developers/",
  },
  milkomedaDocUserCardano: {
    [Locales.english]: "https://docs.milkomeda.com/cardano/for-end-users/",
    [Locales.japanese]: "https://docs.milkomeda.com/ja/cardano/for-end-users/",
  },
  milkomedaMedium: {
    [Locales.english]: "https://medium.com/@milkomedafoundation",
    [Locales.japanese]: "https://medium.com/milkomeda%E6%97%A5%E6%9C%AC",
  },
  milkomedaUserTutorialAlgorand: {
    [Locales.english]: "https://docs.milkomeda.com/algorand/for-end-users/",
    [Locales.japanese]: "https://docs.milkomeda.com/ja/algorand/for-end-users/",
  },
  milkomedaDeveloperDocsAlgorand: {
    [Locales.english]: "https://docs.milkomeda.com/algorand/for-developers/",
    [Locales.japanese]: "https://docs.milkomeda.com/ja/algorand/for-developers/",
  },
};

export const externalLinks = {
  addTokenBridge: "https://airtable.com/shr8k4usXQFhtDjJf",
};
