import * as React from "react";
import { Link, Trans, useI18next } from "gatsby-plugin-react-i18next";
import Accordion from "./Accordion";
import { LANGUAGES, Locales } from "../../locales/languages";
import LinkAccordion from "./LinkAccordion";
import LinkAccordionCommunity from "./LinkAccordionCommunity";
import LinkAccordionUseMilkomeda from "./LinkAccordionUseMilkomeda";
import { externalUrlsByLocale, externalLinks } from "../utils/constants";
import { addA1Milkomeda, addC1Milkomeda } from "../utils/evmHandlers";
import classNames from "classnames";

const MilkHeader = () => {
  const { language: currentLanguage, languages, originalPath } = useI18next();
  const [mobileMenu, setMobileMenu] = React.useState(false);

  const closeMenu = () => setMobileMenu(false);
  return (
    <>
      <div className="fixed top-0 left-0 z-50">
        {/* Mobile menu */}
        <nav
          className={classNames(
            "mobile-main-nav z-0 w-full overflow-auto pb-16",
            mobileMenu && "active"
          )}
          style={{ maxHeight: "calc(100vh - 70px)" }}
        >
          <div className="container">
            <ul className="-mx-10 flex flex-col items-center lg:flex-row">
              <div className="w-full border-t border-gray-700" />
              <li className="block w-full">
                <div className="main-nav-link pb-0">
                  <LinkAccordion />
                </div>
              </li>
              <div className="w-full border-t border-gray-700" />
              <li className="block w-full">
                <div className="main-nav-link pb-0">
                  <LinkAccordionUseMilkomeda
                    addC1Milkomeda={addC1Milkomeda}
                    addA1Milkomeda={addA1Milkomeda}
                  />
                </div>
              </li>
              <div className="w-full border-t border-gray-700 mb-24" />
              <li className="block w-full">
                <Link to="/dapp-store" onClick={closeMenu} className="main-nav-link">
                  <Trans>DApp Store</Trans>
                </Link>
              </li>
              <div className="w-full border-t border-gray-700 mt-20" />
              <li className="block w-full">
                <div className="main-nav-link pb-0">
                  <LinkAccordionCommunity closeMenu={closeMenu} />
                </div>
              </li>
              <div className="w-full border-t border-gray-700 mb-24" />
              <li className="block w-full">
                <a
                  href="https://medium.com/@milkomedafoundation"
                  target="_blank"
                  rel="noopener"
                  className="main-nav-link"
                >
                  <Trans>Blog</Trans>
                </a>
              </li>
              <li className="block w-full">
                <Link to="/support" onClick={closeMenu} className="main-nav-link">
                  <Trans>Support</Trans>
                </Link>
              </li>
              <li className="block w-full">
                <Link to="/contact" onClick={closeMenu} className="main-nav-link">
                  <Trans>Contact</Trans>
                </Link>
              </li>
              <div className="w-full border-t border-gray-700 mt-20" />
              <li className="block w-full">
                <div className="main-nav-link">
                  <Accordion />
                </div>
              </li>
              <div className="w-full border-t border-gray-700 mb-24" />
              <li className="block w-full mt-10 bg-orange rounded-full">
                <a
                  href={
                    externalUrlsByLocale.milkomedaDocDeveloperCardano[currentLanguage as Locales]
                  }
                  target="_blank"
                  rel="noopener"
                  className="main-nav-link"
                >
                  <Trans>Start building</Trans>
                </a>
              </li>
              <li className="block w-full mt-24 bg-blue border border-white transition-all hover:bg-white hover:text-blue-bg-dark rounded-full">
                <a
                  href={externalUrlsByLocale.milkomedaDocUserCardano[currentLanguage as Locales]}
                  target="_blank"
                  rel="noopener"
                  className="main-nav-link"
                >
                  <Trans>How to connect?</Trans>
                </a>
              </li>
            </ul>
          </div>
        </nav>

        <header className={classNames("header relative z-10", mobileMenu && "active")}>
          <div className="container flex justify-between">
            <Link to="/" className="block w-156 h-22">
              <img
                className="image-sd"
                src="/images/logo.svg"
                width="155"
                height="22"
                alt="Milkomeda logo"
              />
            </Link>
            <nav className="main-nav hidden lg:block">
              <ul className="-mx-15 flex flex-col items-center lg:flex-row">
                <li className="block w-full build-hover">
                  <div className="main-nav-link dropdown relative p-0">
                    <div className="dropdown-selected bg-transparent py-0 px-20">
                      <Trans>
                        Build<i className="arrow down ml-8 mb-2"></i>
                      </Trans>
                    </div>
                    <ul className="build-dropdown dropdown-list w-188 mt-0">
                      <li className="dropdown-list-item-lang">
                        <a
                          href={
                            externalUrlsByLocale.milkomedaDocDeveloperCardano[
                              currentLanguage as Locales
                            ]
                          }
                          target="_blank"
                          rel="noopener"
                        >
                          <Trans>Documentation</Trans>
                        </a>
                      </li>{" "}
                      <li className="dropdown-list-item-lang">
                        <Link to="/incubator">
                          <Trans>Milkomeda Incubator</Trans>
                        </Link>
                      </li>
                      <li className="dropdown-list-item-lang">
                        <Link to="/demo-day">
                          <Trans>Accelerator Demo Day</Trans>
                        </Link>
                      </li>
                      {/*<li className="dropdown-list-item-lang">*/}
                      {/*  <Link to="/hackathon">*/}
                      {/*    <Trans>DAO Hackathon</Trans>*/}
                      {/*  </Link>*/}
                      {/*</li>*/}
                      {/*<li className="dropdown-list-item-lang">*/}
                      {/*  <Link to="/hackathon-defi">*/}
                      {/*    <Trans>DeFi Shippers Hackathon</Trans>*/}
                      {/*  </Link>*/}
                      {/*</li>*/}
                      <li className="dropdown-list-item-lang">
                        <a href={externalLinks.addTokenBridge} target="_blank" rel="noopener">
                          <Trans>Add Token to the Bridge</Trans>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="block w-full build-hover">
                  <div className="main-nav-link dropdown relative p-0">
                    <div className="dropdown-selected bg-transparent py-0 px-20">
                      <Trans>
                        Use Milkomeda <i className="arrow down ml-8 mb-2"></i>
                      </Trans>
                    </div>
                    <ul className="build-dropdown dropdown-list w-188 mt-0">
                      <span className="mt-6 cursor-default block text-left uppercase py-10 px-12 text-disabled text-10">
                        Algorand (A1)
                      </span>
                      <li className="dropdown-list-item-lang">
                        <button onClick={addA1Milkomeda}>
                          <Trans>Add A1 to MetaMask</Trans>
                        </button>
                      </li>
                      <li className="dropdown-list-item-lang">
                        <a
                          href="https://algorand-bridge.milkomeda.com/mainnet"
                          target="_blank"
                          rel="noopener"
                        >
                          <Trans>Milkomeda A1 Bridge</Trans>
                        </a>
                      </li>
                      <li className="dropdown-list-item-lang">
                        <a
                          href="https://bridge-explorer.milkomeda.com/algorand-mainnet"
                          target="_blank"
                          rel="noopener"
                        >
                          <Trans>Milkomeda A1 Bridge Explorer</Trans>
                        </a>
                      </li>
                      <li className="dropdown-list-item-lang">
                        <a
                          href="https://explorer-mainnet-algorand-rollup.a1.milkomeda.com/"
                          target="_blank"
                          rel="noopener"
                        >
                          <Trans>Milkomeda A1 Blockchain Explorer</Trans>
                        </a>
                      </li>
                      <span className="mt-6 cursor-default block text-left uppercase py-10 px-12 text-disabled text-10 border-t-[1px] border-grey-outline pt-20">
                        Cardano (C1)
                      </span>
                      <li className="dropdown-list-item-lang">
                        <button onClick={addC1Milkomeda}>
                          <Trans>Add C1 to MetaMask</Trans>
                        </button>
                      </li>
                      <li className="dropdown-list-item-lang">
                        <a
                          href={
                            externalUrlsByLocale.milkomedaDocUserCardano[currentLanguage as Locales]
                          }
                          target="_blank"
                          rel="noopener"
                        >
                          <Trans>User Tutorials</Trans>
                        </a>
                      </li>
                      <li className="dropdown-list-item-lang">
                        <a
                          href={
                            externalUrlsByLocale.milkomedaDocDeveloperCardano[
                              currentLanguage as Locales
                            ]
                          }
                          target="_blank"
                          rel="noopener"
                        >
                          <Trans>Developer Docs</Trans>
                        </a>
                      </li>
                      <li className="dropdown-list-item-lang">
                        <a
                          href="https://cardano-bridge.milkomeda.com/bridge"
                          target="_blank"
                          rel="noopener"
                        >
                          <Trans>Milkomeda C1 Bridge</Trans>
                        </a>
                      </li>
                      <li className="dropdown-list-item-lang">
                        <a
                          href="https://bridge-explorer.milkomeda.com/cardano-mainnet"
                          target="_blank"
                          rel="noopener"
                        >
                          <Trans>Milkomeda C1 Bridge Explorer</Trans>
                        </a>
                      </li>
                      <li className="dropdown-list-item-lang">
                        <a
                          href="https://explorer-mainnet-cardano-evm.c1.milkomeda.com/"
                          target="_blank"
                          rel="noopener"
                        >
                          <Trans>Milkomeda C1 Blockchain Explorer</Trans>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="block w-full">
                  <Link to="/dapp-store" className="main-nav-link pr-20">
                    <Trans>DApp Store</Trans>
                  </Link>
                </li>
                <li className="block w-full build-hover">
                  <div className="main-nav-link dropdown relative p-0">
                    <div className="dropdown-selected bg-transparent py-0 px-20">
                      <Trans>
                        Community<i className="arrow down ml-8 mb-2"></i>
                      </Trans>
                    </div>
                    <ul className="build-dropdown dropdown-list w-188 mt-0">
                      <li className="dropdown-list-item-lang">
                        <Link to="/community">
                          <Trans>Follow us and News</Trans>
                        </Link>
                      </li>
                      <li className="dropdown-list-item-lang">
                        <Link to="/grant-webpage">
                          <Trans>Grant Proposals</Trans>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="block w-full">
                  <a
                    href={externalUrlsByLocale.milkomedaMedium[currentLanguage as Locales]}
                    target="_blank"
                    rel="noopener"
                    className="main-nav-link"
                  >
                    <Trans>Blog</Trans>
                  </a>
                </li>
                <li className="block w-full">
                  <Link to="/support" className="main-nav-link pr-20">
                    <Trans>Support</Trans>
                  </Link>
                </li>
                <li className="block w-full language-hover">
                  <div className="main-nav-link dropdown relative p-0">
                    <div className="dropdown-selected bg-transparent pl-20 py-0 mr-30">
                      <Trans>
                        {LANGUAGES[currentLanguage as Locales]}
                        <i className="arrow down ml-8 mb-2"></i>
                      </Trans>
                    </div>
                    <ul className="dropdown-list w-188 mt-0 language-dropdown">
                      {languages.map((lang) => (
                        <li className="dropdown-list-item-lang" key={lang}>
                          <Link to={originalPath} language={lang} className="lg:w-60">
                            <Trans>{LANGUAGES[lang as Locales]}</Trans>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
              </ul>
            </nav>
            <div
              onClick={() => setMobileMenu((prev) => !prev)}
              className={classNames("cursor-pointer burger-icon lg:hidden", mobileMenu && "active")}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

export default MilkHeader;
