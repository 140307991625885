import React, { useRef, useState } from "react";
import { Trans, useTranslation, Link } from "gatsby-plugin-react-i18next";

export const LinkAccordionCommunity = ({ closeMenu }: { closeMenu: () => void }) => {
  const [active, setActive] = useState<boolean>(false);
  const [height, setHeight] = useState<string>("0px");
  const [rotate, setRotate] = useState<string>("transform duration-700 ease");
  const contentSpace = useRef<HTMLDivElement>(null);

  function toggleAccordion() {
    setActive((prev) => !prev);
    setHeight(active ? "0px" : `${contentSpace.current?.scrollHeight ?? 0}px`);
    setRotate(active ? "transform duration-300 ease" : "transform duration-700 ease rotate-180");
  }

  return (
    <div className="flex flex-col">
      <button
        className="py-6 box-border appearance-none cursor-pointer focus:outline-none flex items-center justify-center flex flex-row gap-6 cursor-default"
        onClick={toggleAccordion}
      >
        <p className="inline-block text-footnote text-center text-14 font-medium whitespace-nowrap lg:hover:text-white transition">
          <Trans>Community</Trans>
        </p>
        <svg
          className={`${rotate} inline-block w-16 h-16 fill-current`}
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.6894 5.28443C13.2753 4.90519 12.6029 4.90519 12.1888 5.28443L8.00025 9.51089L3.81118 5.28443C3.39707 4.90519 2.7247 4.90519 2.31058 5.28443C1.89647 5.66367 1.89647 6.2805 2.31058 6.65974L7.18352 11.5761C7.40781 11.7818 7.70703 11.8731 8.00025 11.8549C8.29348 11.8731 8.5922 11.7818 8.81648 11.5761L13.6894 6.65974C14.1035 6.2805 14.1035 5.66367 13.6894 5.28443Z"
            fill="white"
          />
        </svg>
      </button>
      <div
        ref={contentSpace}
        style={{ maxHeight: `${height}` }}
        className="overflow-hidden transition-max-height duration-300 ease-in-out"
      >
        <div>
          <ul>
            <li className="dropdown-list-item-lang">
              <Link to="/community" onClick={closeMenu} className="py-10">
                <Trans>Follow us and News</Trans>
              </Link>
            </li>
            <li className="dropdown-list-item-lang">
              <Link to="/grant-webpage" onClick={closeMenu} className="py-10">
                <Trans>Grant Proposals</Trans>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LinkAccordionCommunity;
