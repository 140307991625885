import React, { useRef, useState } from "react";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";
import { externalUrlsByLocale } from "../utils/constants";
import { Locales } from "../../locales/languages";

export const LinkAccordionUseMilkomeda = ({
  addC1Milkomeda,
  addA1Milkomeda,
}: {
  addC1Milkomeda: () => Promise<void>;
  addA1Milkomeda: () => Promise<void>;
}) => {
  const { language } = useI18next();

  const [active, setActive] = useState<boolean>(false);
  const [height, setHeight] = useState<string>("0px");
  const [rotate, setRotate] = useState<string>("transform duration-700 ease");
  const contentSpace = useRef<HTMLDivElement>(null);

  function toggleAccordion() {
    setActive((prev) => !prev);
    setHeight(active ? "0px" : `${contentSpace.current?.scrollHeight ?? 0}px`);
    setRotate(active ? "transform duration-300 ease" : "transform duration-700 ease rotate-180");
  }

  return (
    <div className="flex flex-col">
      <button
        className="py-6 box-border appearance-none focus:outline-none flex items-center justify-center flex-row gap-6 cursor-default"
        onClick={toggleAccordion}
      >
        <p className="inline-block text-footnote text-center text-14 font-medium whitespace-nowrap lg:hover:text-white transition">
          <Trans>Use Milkomeda</Trans>
        </p>
        <svg
          className={`${rotate} inline-block w-16 h-16 fill-current`}
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.6894 5.28443C13.2753 4.90519 12.6029 4.90519 12.1888 5.28443L8.00025 9.51089L3.81118 5.28443C3.39707 4.90519 2.7247 4.90519 2.31058 5.28443C1.89647 5.66367 1.89647 6.2805 2.31058 6.65974L7.18352 11.5761C7.40781 11.7818 7.70703 11.8731 8.00025 11.8549C8.29348 11.8731 8.5922 11.7818 8.81648 11.5761L13.6894 6.65974C14.1035 6.2805 14.1035 5.66367 13.6894 5.28443Z"
            fill="white"
          />
        </svg>
      </button>
      <div
        ref={contentSpace}
        style={{ maxHeight: `${height}` }}
        className="overflow-hidden transition-max-height duration-300 ease-in-out"
      >
        <div>
          <ul>
            <li className="dropdown-list-item-lang">
              <a
                href={externalUrlsByLocale.milkomedaDoc[language as Locales]}
                target="_blank"
                rel="noopener"
                className="py-10"
              >
                <Trans>Start Here Tutorials</Trans>
              </a>
            </li>
            <span className="block text-center uppercase py-10 px-12 text-disabled text-10 ">
              Algorand (A1)
            </span>
            <li className="dropdown-list-item-lang">
              <button onClick={addA1Milkomeda} className="py-10">
                <Trans>Add A1 to MetaMask</Trans>
              </button>
            </li>
            <li className="dropdown-list-item-lang">
              <a
                href="https://algorand-bridge.milkomeda.com/mainnet"
                target="_blank"
                rel="noopener"
                className="py-10"
              >
                <Trans>Milkomeda A1 Bridge</Trans>
              </a>
            </li>
            <li className="dropdown-list-item-lang">
              <a
                href="https://bridge-explorer.milkomeda.com/algorand-mainnet"
                target="_blank"
                rel="noopener"
                className="py-10"
              >
                <Trans>Milkomeda A1 Bridge Explorer</Trans>
              </a>
            </li>
            <li className="dropdown-list-item-lang">
              <a
                href="https://explorer-mainnet-algorand-rollup.a1.milkomeda.com/"
                target="_blank"
                rel="noopener"
                className="py-10"
              >
                <Trans>Milkomeda A1 Blockchain Explorer</Trans>
              </a>
            </li>

            <span className="block text-center uppercase py-10 px-12 text-disabled text-10">
              Cardano (C1)
            </span>

            <li className="dropdown-list-item-lang">
              <button onClick={addC1Milkomeda} className="py-10">
                <Trans>Add C1 to MetaMask</Trans>
              </button>
            </li>
            <li className="dropdown-list-item-lang">
              <a
                href="https://cardano-bridge.milkomeda.com/bridge"
                target="_blank"
                rel="noopener"
                className="py-10"
              >
                <Trans>Milkomeda C1 Bridge</Trans>
              </a>
            </li>
            <li className="dropdown-list-item-lang">
              <a
                href="https://bridge-explorer.milkomeda.com/cardano-mainnet"
                target="_blank"
                rel="noopener"
                className="py-10"
              >
                <Trans>Milkomeda C1 Bridge Explorer</Trans>
              </a>
            </li>
            <li className="dropdown-list-item-lang">
              <a
                href="https://explorer-mainnet-cardano-evm.c1.milkomeda.com/"
                target="_blank"
                rel="noopener"
                className="py-10"
              >
                <Trans>Milkomeda C1 Blockchain Explorer</Trans>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LinkAccordionUseMilkomeda;
